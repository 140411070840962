import Icon from '@react/widget/Icon';
import React, { useState } from "react";
import Search from "@react/widget/Search";

interface Props {
  className?: string;
  localePath?: string;
  label?: {
    search: {
      placeholder: string;
    };
  }
}
const MobileSearch: React.FC<Props> = ({className = "lg:hidden relative", localePath = "", label}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={className}>
      <div className={"flex justify-between cursor-pointer p-2"} onClick={() => setOpen(!open)}>
        <Icon className="w-4" name={open ? "close" : "search"}/>
      </div>
      { !!open && (
        <div className={[
          "fixed top-[90px] right-0 bottom-0 left-0 z-50 bg-white px-5 py-4 border-t",
          "sm:absolute sm:[top:100%] sm:bottom-auto sm:left-auto sm:[right:-1rem] sm:border-none sm:drop-shadow-md"
        ].join(" ")}>
            <Search className="block" width="w-full" placeholder={label?.search?.placeholder} localePath={localePath}/>
        </div>
      ) }
    </div>
  );
};
export default MobileSearch;